import React, { Component } from "react";
import { withRouter } from "react-router";
import { withModalsCtx } from "../../contexts/ModalsContext";
import { withWareCtx } from "../../contexts/WarehouseContext";
import DGenericDoc from "../../components/DGenericDoc";
import { withAdminCtx } from "../../contexts/AdminContext";
import DocumentType from "../../enums/DocumentType";
import firebase from "firebase/compat/app";
import {
  Button,
  AlertDialog,
  AlertDialogBody,
  AlertDialogContent,
  AlertDialogFooter,
  AlertDialogHeader,
  AlertDialogOverlay,
  Flex,
  Heading,
  SimpleGrid,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalBody,
  ModalFooter
} from "@chakra-ui/react";
import StorageService from "../../services/StorageService";
import MyTable from "../../components/admin/MyTable";

class DDocumentDetails extends Component {
  state = {
    fornitori: [],
    clienti: [],
    showSaveConfirm: false,
    savedDoc: null
  };

  async uploadPDF(doc, file) {
    console.log("AA file", file, doc);
    if (!!file) {
      try {
        await StorageService.postPDF(file, doc.pdfFilename, doc.uid, "pdf");
        alert("PDF caricato correttamente");
      } catch (e) {
        console.error("Error while uploading the pdf: ", e);
        alert(
          "Si è verificato un errore durante il caricamento del pdf: ${e.message}"
        );
      }
    }
  }

  async onSave(p, file) {
    console.log("AA p", p, file);
    if (this.props.adding) {
      let docUid = null;
      const docRef = await firebase
        .firestore()
        .collection("documents")
        .add(p)
        .then(docRef => (p.uid = docRef.id));

      await this.props.admin.changeProductQuantity(p, false);
      console.log("BBB file", p, file);
    } else {
      const docRef = await firebase
        .firestore()
        .collection("documents")
        .doc(p.uid)
        .update(p);
    }

    if (!!p.pdfFilename) {
      this.uploadPDF(p, file);
    }
  }

  renderConfirmSave() {
    return (
      <AlertDialog
        isOpen={!!this.state.showSaveConfirm}
        onClose={() => this.setState({ showSaveConfirm: false })}
      >
        <AlertDialogOverlay>
          <AlertDialogContent>
            <AlertDialogHeader fontSize="lg" fontWeight="bold">
              Salvataggio effettuato
            </AlertDialogHeader>

            <AlertDialogFooter>
              <Button
                onClick={() =>
                  this.setState({ showSaveConfirm: false }, () =>
                    this.props.history.push("/uscita")
                  )
                }
              >
                OK
              </Button>
            </AlertDialogFooter>
          </AlertDialogContent>
        </AlertDialogOverlay>
      </AlertDialog>
    );
  }

  async creaDDT(doc) {
    doc.type =
      doc.causale.toLowerCase() == "acquisto"
        ? DocumentType.ENTRATA
        : DocumentType.DISTINTA;

    console.log("AA creatddt", doc);

    doc.date = new Date();
    doc.preventivo = doc.codeNumber || "-";
    // doc.causale = "Vendita";
    // doc.canaleVendita = "Myrebranding";
    doc.creatorName = firebase.auth().currentUser.displayName;
    doc.creator = firebase.auth().currentUser.uid;

    delete doc.dateFormatted;
    delete doc.number;
    delete doc.codeNumber;
    delete doc.uid;

    console.log("AA ddt", doc);

    await firebase
      .firestore()
      .collection("documents")
      .add(doc)
      .then(docRef => (doc.uid = docRef.id));
    this.props.admin.changeProductQuantity(doc, false);

    this.props.history.push("/uscita");
  }

  componentDidMount() {
    console.log("AA add", this.props.adding);
  }

  getEntrataFields() {
    console.log("AA get ENTRATA");
    const fields = [
      {
        Header: "Nominativo",
        accessor: "contact",
        subaccessor: "name",
        type: "search",
        values: this.props.admin.contacts
      },
      {
        Header: "Numero ordine fornitore",
        accessor: "docNumberFornitore",
        type: "text"
      },
      {
        Header: "Data Ordine",
        accessor: "dataOrdine",
        type: "date"
      },
      {
        Header: "Causale",
        accessor: "causale",
        type: "multiValues",
        values: ["-", "Acquisto", "Reso"]
      },
      {
        Header: "Canale di Acquisto",
        accessor: "canaleAcquisto",
        type: "multiValues",
        values: [
          "-",
          "Materie Prime",
          "Packaging",
          "Prodotto finito",
          "Imballaggio",
          "Ufficio"
        ],
        showCondition: ["causale", "acquisto"]
      },
      {
        Header: "Data Ricezione",
        accessor: "dataRicezione",
        type: "date"
      },
      {
        Header: "Numero Colli",
        accessor: "colli",
        type: "number"
      },
      {
        Header: "PDF Upload",
        accessor: "pdf",
        type: "pdf",
        modifiable: true
      },
      {
        Header: "Creato da",
        accessor: "creatorName",
        blocked: true
      }
    ];

    return fields;
  }

  getPreventivoFields() {
    const fields = [
      {
        type: "row",
        fields: [
          {
            Header: "Nominativo",
            accessor: "contact",
            subaccessor: "name",
            type: "search",
            values: this.props.admin.contacts,
            style: { minWidth: "330px" }
          },
          {
            Header: "Indirizzo spedizione",
            accessor: "shippingMulti",
            subaccessor: "nome",
            type: "multiValues",
            dbField: "shippingAddress"
          }
        ]
      },
      {
        type: "row",
        style: { maxWidth: "100px" },
        fields: [
          {
            Header: "Causale",
            accessor: "causale",
            type: "multiValues",
            values: ["-", "Acquisto", "Vendita"]
          },
          {
            Header: "Canale di vendita",
            accessor: "canaleVenditaMultiplo",
            type: "multiValues",
            values: [
              "Sito",
              "Altri marketplace",
              "Affiliati",
              "Partner",
              "Rivenditori",
              "Istituzionali"
            ],
            showCondition: ["causale", "vendita"]
          },
          {
            Header: "Paese",
            accessor: "paeseVendita",
            type: "multiValues",
            values: [
              "Italia",
              "Francia",
              "Germania",
              "Spagna",
              "Olanda",
              "Svezia",
              "Polonia",
              "Belgio",
              "Regno Unito"
            ],
            showCondition: ["causale", "vendita"]
          },
          {
            Header: "Canale di acquisto",
            accessor: "canaleAcquisto",
            type: "multiValues",
            values: [
              "Materie Prime",
              "Packaging",
              "Prodotto Finito",
              "Imballaggio",
              "Ufficio"
            ],
            showCondition: ["causale", "acquisto"]
          },
          {
            Header: "Modalità pagamento",
            accessor: "modalitaPagamento",
            type: "multiValues",
            values: ["-", "Contrassegno", "Bonifico", "Altro"]
          }
        ]
      },
      {
        Header: "Data Spedizione",
        accessor: "dataSpedizione",
        type: "date",
        modifiable: true
      },
      {
        Header: "Note",
        accessor: "note",
        type: "textField"
      },
      {
        Header: "Creato da",
        accessor: "creatorName",
        blocked: true
      }
    ];

    return fields;
  }

  getDistintaFields() {
    const fields = [
      {
        type: "row",
        fields: [
          {
            Header: "Nominativo",
            accessor: "contact",
            subaccessor: "name",
            type: "search",
            values: this.props.admin.contacts
          },
          {
            Header: "Indirizzo spedizione",
            accessor: "shippingMulti",
            subaccessor: "nome",
            type: "multiValues",
            dbField: "shippingAddress"
          }
        ]
      },
      {
        Header: "Rif preventivo",
        accessor: "preventivo",
        type: "text"
      },
      {
        type: "row",
        fields: [
          {
            Header: "Causale",
            accessor: "causale",
            type: "multiValues",
            values: [
              "-",
              "Acquisto",
              "Vendita",
              "Danneggiato",
              "Omaggio",
              "Autoconsumo"
            ]
          },
          {
            Header: "Canale di vendita",
            accessor: "canaleVenditaMultiplo",
            type: "multiValues",
            values: [
              "Sito",
              "Altri marketplace",
              "Affiliati",
              "Partner",
              "Rivenditori",
              "Istituzionali"
            ]
            // style: { minWidth: "200px" }
          },
          {
            Header: "Paese",
            accessor: "paeseVendita",
            type: "multiValues",
            values: [
              "Italia",
              "Francia",
              "Germania",
              "Spagna",
              "Olanda",
              "Svezia",
              "Polonia",
              "Belgio",
              "Regno Unito"
            ]
          }
        ]
      },

      // {
      //   Header: "Metodo Spedizione",
      //   accessor: "metodoSpedizione",
      //   type: "text",
      //   modifiable: true
      // },
      // {
      //   Header: "Tracking number",
      //   accessor: "trackingNumber",
      //   type: "text",
      //   modifiable: true
      // },

      {
        type: "row",
        fields: [
          {
            Header: "Data Spedizione",
            accessor: "dataSpedizione",
            type: "date",
            modifiable: true
          },
          {
            Header: "Numero Colli",
            accessor: "colli",
            type: "number",
            modifiable: true
          },
          {
            Header: "Aspetto esteriore dei beni",
            accessor: "aspetto",
            type: "multiValues",
            values: ["Cartoni", "Pedana", "Busta", "Altro"],
            modifiable: true
          }
        ]
      },
      {
        Header: "Note",
        accessor: "note",
        type: "textField"
      },
      {
        Header: "Creato da",
        accessor: "creatorName",
        blocked: true
      }
    ];

    return fields;
  }

  getFields() {
    switch (this.props.type || this.props.match.params.pType) {
      case DocumentType.ENTRATA:
        return this.getEntrataFields();
      case DocumentType.PREVENTIVO:
        return this.getPreventivoFields();
      case DocumentType.DISTINTA:
        return this.getDistintaFields();
    }
  }

  onConfirmSave(d) {
    const result = [];
    console.log("zzz confirmsave", d);
    d.products.forEach(p => {
      console.log("zzz confirmsave", p);
      if (p?.prodottiFigli?.length > 0) {
        result.push(p);

        p.prodottiFigli.forEach(async f => {
          console.log("zzz prodottiFigli", f, p);
          f.quantity = p.quantity * f.quantityInBundle;
          f.child = true;
          result.push(f);
        });
      } else {
        console.log("zzz prodottiFigli NO");

        result.push(p);
      }
    });

    d.result = result;
    console.log("zzz result", d.result, result);

    this.setState({ savedDoc: d });
  }
  render() {
    let fields = this.getFields();

    return (
      <>
        <DGenericDoc
          table="documents"
          uid={this.props.uid || this.props.match.params.pUid}
          fields={fields}
          onCreaDDT={d => this.creaDDT(d)}
          onSave={(d, file) => this.onSave(d, file)}
          onConfirmSave={d => this.onConfirmSave(d)}
          adding={this.props.adding}
          {...this.props}
        />

        {this.renderConfirmSave()}
        {this.renderShowResult()}
      </>
    );
  }

  renderShowResult() {
    const { savedDoc } = this.state;

    savedDoc?.result?.forEach(async p => {
      const pSnap = await firebase
        .firestore()
        .collection("products")
        .doc(p.uid)
        .get();

      const prod = pSnap.data();

      const lotto = prod?.lotti.filter(l => l.name === p.lotto)[0];

      p.lottoQuantity = lotto.quantity;
      p.dopo = p.lottoAvailability - p.quantity;
    });

    const columns = [
      {
        Header: "SKU",
        accessor: "sku"
        // style: { minWidth: "100px" }
      },
      {
        Header: "NOME",
        accessor: "name"
      },
      {
        Header: "COMBO",
        accessor: "combo",
        style: { textAlign: "center" },
        type: "checkbox"
      },
      {
        Header: "LOTTO",
        accessor: "lotto"
      },
      {
        Header: "QT PRIMA",
        accessor: "lottoAvailability",
        style: { textAlign: "right" }
      },
      {
        Header: "QT",
        accessor: "quantity",
        style: { textAlign: "right" }
      },
      {
        Header: "QT DOPO",
        accessor: "dopo",
        style: { textAlign: "right" }
      },
      {
        Header: "QT DB",
        accessor: "lottoQuantity",
        style: { textAlign: "right" }
      }
    ];
    console.log("AA savedDoc", savedDoc);
    return (
      <Modal
        isOpen={this.state.savedDoc}
        onClose={() => this.setState({ savedDoc: null })}
        size={"6xl"}
      >
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>Riepilogo</ModalHeader>
          <ModalBody>
            <div
              className="admin-panels admin-panel"
              style={{ backgroundColor: "red !important", minHeight: "auto" }}
            >
              {
                <MyTable
                  rowStyle={row =>
                    row.dopo != row.lottoQuantity
                      ? { background: "#ff00003d" }
                      : undefined
                  }
                  data={savedDoc?.result || []}
                  columns={columns}
                  hideDelete
                  {...this.props}
                />
              }
            </div>
          </ModalBody>
          <ModalFooter>
            <Button
              colorScheme="green"
              onClick={() => this.props.history.push("/uscita")}
            >
              ESCI
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
    );
  }
}

export default withAdminCtx(
  withModalsCtx(withRouter(withWareCtx(DDocumentDetails)))
);

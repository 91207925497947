import { Columns } from "bloomer";
import { Column } from "bloomer/lib/grid/Column";
import React, { Component } from "react";
import MyTable from "./admin/MyTable";
import { withAdminCtx } from "../contexts/AdminContext";
import { withRouter } from "react-router-dom";
import { withWareCtx } from "../contexts/WarehouseContext";
import CSVReader from "react-csv-reader";
import firebase from "firebase/compat/app";
import times from "../svgs/ic-24-close.svg";
import Utils from "../Utils";

import {
  Button,
  Checkbox,
  Flex,
  Modal,
  ModalBody,
  ModalHeader,
  ModalOverlay,
  ModalContent,
  AlertDialog,
  AlertDialogOverlay,
  AlertDialogContent,
  AlertDialogHeader,
  AlertDialogFooter,
  ModalFooter,
  Box,
  Select
} from "@chakra-ui/react";

class DButtonCSV extends Component {
  state = {};

  replaceHeader(header) {
    header = header.toLowerCase().replace(/\W/g, "_");

    if (this.props.replaceHeader) header = this.props.replaceHeader(header);
    return header;
  }

  renderCSVLoader() {
    const csvOptionsAmazon = {
      loading: false,
      header: true,
      dynamicTyping: true,
      skipEmptyLines: true,
      transformHeader: header => this.replaceHeader(header)
    };

    return (
      <Button colorScheme="green" py={2} h="60px">
        <Box my={2}>
          {this.props.title}
          <CSVReader
            parserOptions={csvOptionsAmazon}
            onFileLoaded={(data, fileInfo) => {
              console.log("AA file loaded");
              console.dir(data, fileInfo);
              this.insertCsv(data);
            }}
          />
        </Box>
      </Button>
    );
  }

  async insertCsv(data) {
    data.forEach(async r => {
      if (this.props.transform) r = this.props.transform(r);

      r.uid = r[this.props.uid];
      Utils.prune(r);

      if (!r.uid) console.error("NESSUNA CHIAVE DEFINITA");
      else console.log("r", r);
    });
    this.setState({ riepilogoData: data, riepilogoModalActive: true });
  }

  renderRiepilogoModal() {
    const { riepilogoData, loadingCSV, insertedRow } = this.state;
    console.log("AA Riepil", riepilogoData);
    const { columns } = this.props;
    console.log("AA riepilogoModalActive", this.state.riepilogoModalActive);
    return (
      <Modal
        isOpen={this.state.riepilogoModalActive}
        onClose={() => this.setState({ riepilogoModalActive: false })}
        size={"6xl"}
      >
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>Riepilogo</ModalHeader>
          <ModalBody>
            <Columns>
              <Column>
                <Button
                  isLoading={loadingCSV}
                  className="primary"
                  onClick={() => this.loadCSV(riepilogoData)}
                >
                  CARICA
                </Button>
              </Column>
              <Column>
                {loadingCSV && (
                  <h1>
                    caricati {insertedRow} di {riepilogoData.length}
                  </h1>
                )}
              </Column>
            </Columns>
            <div className="admin-panel">
              <MyTable
                style={{ maxHeight: "calc(100vh - 300px)" }}
                data={riepilogoData}
                columns={columns}
                hideButtons
                {...this.props}
              />
            </div>
          </ModalBody>
        </ModalContent>
      </Modal>
    );
  }

  async loadCSV(data) {
    this.setState({ loadingCSV: true });

    let index = 0;
    const promises = [];
    data.forEach(async row => {
      if (!!row.uid) {
        promises.push(
          new Promise(async (r, rj) => {
            try {
              if (this.props.update) {
                const { columns } = this.props;
                let obj = {};

                for (const key of columns) {
                  if (!!row[key.accessor])
                    obj[key.accessor] = row[key.accessor];
                }
                if (this.props.updateField) obj = this.props.updateField(row);
                console.log("UPDATEEEEEEEEE", obj);
                await firebase
                  .firestore()
                  .collection(this.props.table)
                  .doc(row.uid)
                  .update({ ...obj });

                index++;

                this.setState({ insertedRow: index });
                r(index);
              } else {
                await firebase
                  .firestore()
                  .collection(this.props.table)
                  .doc(row.uid)
                  .set(row);

                index++;

                this.setState({ insertedRow: index });
                r(index);
              }
            } catch (err) {
              console.log("AA err", err);

              rj(err);
            }
          })
        );
      }
    });

    await Promise.all(promises);

    this.setState({ riepilogoModalActive: false, loadingCSV: false });
  }
  render() {
    return (
      <div style={{ display: "context" }}>
        {this.renderCSVLoader()}
        {this.renderRiepilogoModal()}
      </div>
    );
  }
}

export default withRouter(withAdminCtx(withWareCtx(DButtonCSV)));
